<template>
    <div class="home">
        <div class="overview">
            <div class="ov-head flex-between">
                <span class="label">营业总览</span>
                <div class="flex-center">
                    <span class="item cursor" :class="{'active': active === 0}" @click="changeActive(0)">今日</span>
                    <span class="item cursor" :class="{'active': active === 1}" @click="changeActive(1)">昨日</span>
                    <span class="item cursor" :class="{'active': active === 2}" @click="changeActive(2)">近7日</span>
                    <span class="item cursor" :class="{'active': active === 3}" @click="changeActive(3)">近30日</span>
                    <el-date-picker
                            v-model="date"
                            :clearable="false"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            value-format="yyyy-MM-dd"
                            format="yyyy-MM-dd"
                            @change="chooseDate"
                            class="item cursor"
                    />
                </div>
            </div>
            <div class="ov-content flex-between">
                <div class="item flex-center">
                    <span class="ov-title">营业额</span>
                    <span class="ov-num">{{summary.price}}</span>
                </div>
                <div class="item flex-center">
                    <span class="ov-title">订单数</span>
                    <span class="ov-num">{{summary.count}}</span>
                </div>
                <div class="item flex-center">
                    <span class="ov-title">单均客单价</span>
                    <span class="ov-num">{{summary.average}}</span>
                </div>
            </div>
        </div>
        <!-- <div class="banner flex">
            <img src="https://image.topboom0912.com/admin_static_img/index_banner.png" alt="banner" width="578px">
            <div class="announcement">
                <div class="title flex-between">
                    <span class="label">公告</span>
                    <span class="tomore cursor" @click="toNotice">更多</span>
                </div>
                <div class="content">
                    <div class="item flex-between cursor" :class="{'is-read': item.is_read === 1}"
                         v-for="(item, index) in noticeList" :key="item.id" @click="toOrder(item)">
                        <span>{{index + 1}}.{{item.name}}</span>
                        <span>{{item.date}}</span>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="top">
            <div class="title label">产品Top10（近30日）</div>
            <div class="content">
                <el-table
                        :data="topData"
                        class="tb-table"
                >
                    <el-table-column label="排行">
                        <span slot-scope="scope"
                              :class="scope.$index + 1 <= 3 ? 'blue' : 'grey'">{{scope.row.sort}}</span>
                    </el-table-column>
                    <el-table-column prop="product_name" label="商品" width="500"/>
                    <el-table-column prop="price" label="销售额"/>
                    <el-table-column prop="total_count" label="销量"/>
                </el-table>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Home',
        data() {
            return {
                active: 1, // 0今日，1昨日，2近七日，3进三十日
                summary: {
                    count: 0,
                    price: 0,
                    average: 0
                },
                date: [this.$dayjs().subtract(1, 'day').format('YYYY-MM-DD'), this.$dayjs().subtract(1, 'day').format('YYYY-MM-DD')],
                topData: [],
                noticeList: []
            }
        },
        mounted() {
            this.getTop()
            this.getNotice()
        },
        methods: {
            async getSummary() {
                const url = 'admin/home/order/summary'
                const params = {
                    start_time: this.date[0] + ' 00:00:00',
                    end_time: this.date[1] + ' 23:59:59'
                }
                const data = await this.$https.get(url, {params})
                if (!data) return
                this.summary.count = data.total_count || 0
                this.summary.price = this.$np.divide(data.total_order_price * 1, 100) || 0
                this.summary.average = this.$np.round(this.$np.divide(this.summary.price, this.summary.count), 1) || 0
            },
            async getTop() {
                const url = 'admin/home/order/top/sku'
                const data = await this.$https.get(url)
                if (!data) return
                this.topData = data.map((item, index) => {
                    item.price = this.$np.divide(item.total_sale_price, 100)
                    item.sort = index + 1
                    return item
                })
            },
            async getNotice() {
                const params = {
                    page_no: 0,
                    page_size: 6
                }
                const url = 'admin/notice/brand/list'
                const data = await this.$https.get(url, {params})
                if (!data) return
                data.list.forEach(item => {
                    item.date = this.$dayjs(item.created_at).format('YYYY-MM-DD')
                })
                data.list.sort((a, b) => {
                    return a.is_read - b.is_read
                })
                this.noticeList = data.list
            },
            changeActive(type) {
                this.active = type
                switch (type) {
                    case 0:
                        this.date = [this.$dayjs().format('YYYY-MM-DD'), this.$dayjs().format('YYYY-MM-DD')]
                        break
                    case 1:
                        this.date = [this.$dayjs().subtract(1, 'day').format('YYYY-MM-DD'), this.$dayjs().subtract(1, 'day').format('YYYY-MM-DD')]
                        break
                    case 2:
                        this.date = [this.$dayjs().subtract(7, 'day').format('YYYY-MM-DD'), this.$dayjs().format('YYYY-MM-DD')]
                        break
                    case 3:
                        this.date = [this.$dayjs().subtract(30, 'day').format('YYYY-MM-DD'), this.$dayjs().format('YYYY-MM-DD')]
                        break
                    default:
                        break
                }
                this.getSummary()
            },
            chooseDate() {
                this.active = 4
                this.getSummary()
            },
            toOrder(item) {
                const currentPage = {
                    link: 'home',
                    name: '首页'
                }
                this.$store.commit('changeRoute', currentPage)
                const toPage = {
                    link: 'notice/detail',
                    name: '公告详情'
                }
                this.$store.commit('changeRoute', toPage)
                this.$router.push('/notice/detail?id=' + item.id)
            },
            toNotice() {
                this.$store.commit('showDetail', 'true')
                this.$router.push('/notice/list')
            }
        }
    }
</script>

<style lang="scss" scoped>
    $blue: #1890FF;
    .home {
        padding: 24px;
        box-sizing: border-box;

        .label {
            font-weight: 600;
            color: rgba(0, 0, 0, 0.85);
        }

        .overview {
            background-color: #fff;

            .ov-head {
                padding: 10px 32px;
                border-bottom: 1px solid #ECECEC;
                box-sizing: border-box;

                .item + .item {
                    margin-left: 24px;
                }

                .active {
                    color: $blue;
                }
            }

            .ov-content {
                padding: 24px 48px;

                .item {
                    flex-direction: column;
                    width: 312px;

                    .ov-title {
                        padding-bottom: 12px;
                        font-size: 16px;
                        color: rgba(0, 0, 0, 0.45);
                    }

                    .ov-num {
                        font-size: 36px;
                        font-weight: 600;
                        color: rgba(0, 0, 0, 0.85);
                    }
                }
            }
        }

        .banner {
            margin-top: 24px;

            .announcement {
                flex: 1;
                margin-left: 24px;
                background-color: #fff;

                .title {
                    padding: 16px 24px;
                    border-bottom: 1px solid #ECECEC;
                    box-sizing: border-box;

                    .tomore {
                        text-decoration: none;
                        color: $blue;
                    }
                }

                .content {
                    padding: 0 24px;

                    .item {
                        color: rgba(0, 0, 0, 0.85);
                        font-weight: 600;
                        padding-bottom: 16px;

                        &:first-child {
                            padding-top: 16px;
                        }
                    }

                    .is-read {
                        color: rgba(0, 0, 0, 0.45);
                        font-weight: 400;
                    }
                }
            }
        }

        .top {
            margin-top: 24px;
            background-color: #fff;

            .title {
                padding: 16px 32px;
                border-bottom: 1px solid #E9E9E9;
                box-sizing: border-box;
            }

            .content {
                padding: 24px 32px;

                .blue {
                    color: #fff;
                    padding: 0 6px;
                    border-radius: 50%;
                    background-color: #1890FF;
                }

                .grey {
                    color: rgba(0, 0, 0, 0.65);;
                    padding: 0 6px;
                    border-radius: 50%;
                    background-color: #F0F2F5;
                }
            }
        }
    }
</style>
